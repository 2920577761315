import AdditionalVendorContactEmail from "./AdditionalVendorContactInfo";
import Carton from "./Carton";
import Pallet from "./Pallet";
import ShipmentPo from "./ShipmentPo";
import ShipmentStatusHistory from "./ShipmentStatusHistory";
import Status from "./Status";

export default class Shipment {
  id: number = 0;
  primaryVendorContactName: string = '';
  primaryVendorContactPhone: string = '';
  primaryVendorContactEmail: string = '';
  rocketShippingLoadId: number = 0;
  createDate: Date = new Date();
  dockOpen: Date = new Date();
  dockClose: Date = new Date();
  statusId: number = 0;
  status?: Status;
  numCartonsWithLengthOver48Inches: number = 0;
  shipmentBatchId: number = 0;
  totalCartons: number = 0;
  totalWeight: number = 0;
  pickupDateWindowStart: Date = new Date();
  originName: string = '';
  originAddress1: string = '';
  originAddress2: string = '';
  originCity: string = '';
  originState: string = '';
  originZip: string = '';
  originComments: string = '';
  destinationName: string = '';
  destinationAddress1: string = '';
  destinationAddress2: string = '';
  destinationCity: string = '';
  destinationState: string = '';
  destinationZip: string = '';
  emails?: AdditionalVendorContactEmail[];
  cartons?: Carton[];
  pallets?: Pallet[];
  shipmentPos?: ShipmentPo[];
  shipmentStatusHistories?: ShipmentStatusHistory[];
}  