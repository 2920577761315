import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Input, Table } from "reactstrap";
import Carton from "../../models/Carton";
import Shipment from "../../models/Shipment";
import { ADD_SHIPMENT_CARTON, CLEAR_SHIPMENT_CARTONS, DELETE_ALL_SHIPMENT_CARTONS } from "../../redux/constants/cartonConstants/carton.Constants";
import { CLEAR_SHIPMENT_PALLETS, DELETE_ALL_SHIPMENT_PALLETS } from "../../redux/constants/palletConstants/pallet.Constants";
import { SET_SHIPMENT } from "../../redux/constants/shipmentConstants/Shipment.constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import { CLEAR_SHIPMENT_INFORMATION, SET_SHIPMENT_INFORMATION } from "../../redux/constants/shipmentInformationConstants/ShipmentInformation.Constants";
import { useNotifications } from "../../components/customHooks/useNotifications";
import { ToastContainer } from "react-toastify";
import { iPageElements } from "../../redux/reducers/pageElementsReducer/PageElements.type";
import Pallet from "../../models/Pallet";
import "../ShipmentInformationPage/ShipmentInformationPage.css";

function ShipmentInformationInput() {
    const shipment: Shipment = useSelector((store: ReduxState) => store.ShipmentReducer);
    const pallets: Pallet[] = useSelector((store: ReduxState) => store.PalletReducer);
    const cartons: Carton[] = useSelector((store: ReduxState) => store.CartonReducer);
    const pageElements: iPageElements = useSelector((store: ReduxState) => store.PageElementsReducer);

    const [totalPallets, setTotalPallets] = useState("");
    const [totalCartons, setTotalCartons] = useState("");
    const [over48, setOver48] = useState(false);
    const [qtyOver48, setQtyOver48] = useState("");
    const [length1, setLength1] = useState("");
    const [width1, setWidth1] = useState("");
    const [height1, setHeight1] = useState("");
    const [qty1, setQty1] = useState("");
    const [singleCartonSize, setSingleCartonSize] = useState(false);
    const [collectCartonWeight, setCollectCartonWeight] = useState(false);
    const [cartonWeight, setCartonWeight] = useState(0);
    const [length2, setLength2] = useState("");
    const [width2, setWidth2] = useState("");
    const [height2, setHeight2] = useState("");
    const [qty2, setQty2] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let newCarton: Carton = new Carton();
    const styleDarkMode = pageElements.isDarkTheme ? "k-text-light" : "";

    function saveShipmentInformation() {
        const updatedShipment: Shipment = {
            id: shipment.id,
            primaryVendorContactName: shipment.primaryVendorContactName,
            primaryVendorContactPhone: shipment.primaryVendorContactPhone,
            primaryVendorContactEmail: shipment.primaryVendorContactEmail,
            rocketShippingLoadId: 0,
            createDate: shipment.createDate,
            dockOpen: shipment.dockOpen,
            dockClose: shipment.dockClose,
            statusId: shipment.statusId,
            numCartonsWithLengthOver48Inches: 0,
            shipmentBatchId: shipment.shipmentBatchId,
            totalCartons: Number(totalCartons),
            totalWeight: shipment.totalWeight,
            pickupDateWindowStart: shipment.pickupDateWindowStart,
            originName: shipment.originName,
            originAddress1: shipment.originAddress1,
            originAddress2: shipment.originAddress2,
            originCity: shipment.originCity,
            originState: shipment.originState,
            originZip: shipment.originZip,
            originComments: shipment.originComments,
            destinationName: shipment.destinationName,
            destinationAddress1: shipment.destinationAddress1,
            destinationAddress2: shipment.destinationAddress2,
            destinationCity: shipment.destinationCity,
            destinationState: shipment.destinationState,
            destinationZip: shipment.destinationZip,
        };

        const convertedOver48 = Number(qtyOver48);
        if (Number.isNaN(convertedOver48)) {
            updatedShipment.numCartonsWithLengthOver48Inches = 0;
        } else {
            updatedShipment.numCartonsWithLengthOver48Inches = Number(qtyOver48);
        }

        dispatch({ type: SET_SHIPMENT, payload: updatedShipment });
        dispatch({ type: SET_SHIPMENT_INFORMATION, payload: totalPallets });
        addCartonsToShipment();
    }

    function addCartonsToShipment() {
        newCarton = {
            id: 0,
            shipmentId: shipment.id,
            length: parseInt(length1),
            width: parseInt(width1),
            height: parseInt(height1),
            qty: parseInt(qty1),
        };
        dispatch({ type: ADD_SHIPMENT_CARTON, payload: newCarton });

        if (!singleCartonSize) {
            newCarton = {
                id: 0,
                shipmentId: shipment.id,
                length: parseInt(length2),
                width: parseInt(width2),
                height: parseInt(height2),
                qty: parseInt(qty2),
            };

            dispatch({ type: ADD_SHIPMENT_CARTON, payload: newCarton });
        }

        const pallets: number = Number(totalPallets);

        if (pallets !== 0 && !Number.isNaN(pallets)) {
            navigate("/ShipmentInformationDetailsPage");
        } else {
            setCollectCartonWeight(!collectCartonWeight);
        }
    }

    function shipmentWeightFromCarton() {
        let shipmentToUpdate: Shipment = shipment;
        let totalWeight: number = Number(totalCartons) * cartonWeight;
        if (!Number.isNaN(totalWeight)) {
            shipmentToUpdate.totalWeight = totalWeight;
            dispatch({ type: SET_SHIPMENT, payload: shipmentToUpdate });
            navigate("/ShipmentReviewPage");
        } else {
            setCollectCartonWeight(!collectCartonWeight);
        }
    }

    function clearInputs() {
        setTotalPallets("");
        setTotalCartons("");
        setOver48(false);
        setQtyOver48("");
        setLength1("");
        setWidth1("");
        setHeight1("");
        setQty1("");
        setLength2("");
        setWidth2("");
        setHeight2("");
        setQty2("");
        setSingleCartonSize(false);
    }

    function setAndClearOver48() {
        setOver48(false);
        setQtyOver48("");
    }

    function singleSize() {
        setLength2("");
        setWidth2("");
        setHeight2("");
        setQty2("");
        setSingleCartonSize(!singleCartonSize);
    }

    function disableSaveBtn(): boolean | undefined {
        if (singleCartonSize && (totalPallets === "" || totalCartons === "" || length1 === "" || width1 === "" || height1 === "" || qty1 === "")) {
            return true;
        } else if (singleCartonSize && (parseInt(length1) === 0 || parseInt(width1) === 0 || parseInt(height1) === 0 || parseInt(qty1) === 0)) {
            return true;
        } else if (!singleCartonSize && (totalPallets === "" || totalCartons === "" || length1 === "" || width1 === "" || height1 === "" || qty1 === "" ||
            length2 === "" || width2 === "" || height2 === "" || qty2 === "")) {
            return true;
        } else if (!singleCartonSize && (parseInt(length1) === 0 || parseInt(width1) === 0 || parseInt(height1) === 0 || parseInt(qty1) === 0 ||
            parseInt(length2) === 0 || parseInt(width2) === 0 || parseInt(height2) === 0 || parseInt(qty2) === 0)) {
            return true;
        } else if (Number(qty1) + Number(qty2) > Number(totalCartons)) {
            window.alert("Too many cartons, please check your information");
            setQty1("");
            setQty2("");
            return true;
        } else if (collectCartonWeight) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (pallets.length > 0) {
            shipment.totalWeight = 0;
            dispatch({ type: DELETE_ALL_SHIPMENT_PALLETS, payload: shipment.id });
            dispatch({ type: CLEAR_SHIPMENT_PALLETS });
        }
        if (cartons.length > 0) {
            shipment.numCartonsWithLengthOver48Inches = 0;
            shipment.totalCartons = 0;
            dispatch({ type: DELETE_ALL_SHIPMENT_CARTONS, payload: shipment.id });
            dispatch({ type: CLEAR_SHIPMENT_CARTONS });
        }
        dispatch({ type: CLEAR_SHIPMENT_INFORMATION });
        dispatch({ type: SET_SHIPMENT, payload: shipment });
        // eslint-disable-next-line
    }, []);

    useNotifications();

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={pageElements.isDarkTheme ? "dark" : "light"}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    margin: "0 auto",
                    // marginTop: "-15%",
                    position: "relative",
                    bottom: "2em",
                }}
            >
                <Table style={{ width: "80%" }}>
                    <thead>
                        <tr>
                            <th>
                                <h2 className={styleDarkMode}>Basic Shipment Information</h2>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Input
                                    className="input-styling"
                                    maxLength={128}
                                    placeholder="Total Number of Pallets"
                                    value={totalPallets}
                                    onChange={(e) => setTotalPallets(e.target.value)}
                                />
                                <Input
                                    className="input-styling"
                                    maxLength={128}
                                    placeholder="Total Number of Cartons"
                                    value={totalCartons}
                                    onChange={(e) => setTotalCartons(e.target.value)}
                                />
                                <h5 className={styleDarkMode + " input-styling"}>Any cartons over 48 in. length?</h5>
                                <div className="button-input-container">
                                    <ButtonGroup>
                                        <Button color="primary" outline onClick={() => setAndClearOver48()} active={!over48}>
                                            No
                                        </Button>
                                        <Button color="primary" outline onClick={() => setOver48(true)} active={over48}>
                                            Yes
                                        </Button>
                                    </ButtonGroup>
                                    {over48 ? (
                                        <div>
                                            <Input
                                                className="input-styling left-indent"
                                                maxLength={128}
                                                placeholder="How many?"
                                                value={qtyOver48}
                                                onChange={(e) => setQtyOver48(e.target.value)}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div>
                                    <h5 className={styleDarkMode + " input-styling"}>Only 1 carton size?</h5>
                                    <ButtonGroup>
                                        <Button color="primary" outline onClick={() => singleSize()} active={!singleCartonSize}>
                                            No
                                        </Button>
                                        <Button color="primary" outline onClick={() => singleSize()} active={singleCartonSize}>
                                            Yes
                                        </Button>
                                    </ButtonGroup>
                                </div>
                                <div>
                                    {!singleCartonSize ? (
                                        <div>
                                            <h5 className={styleDarkMode + " input-styling"}>Two Largest Carton Sizes (0 is not a valid dimension):</h5>
                                            <div style={{ display: "flex" }}>
                                                <Input
                                                    className="input-styling"
                                                    placeholder="Length"
                                                    value={length1}
                                                    onChange={(e) => setLength1(e.target.value)}
                                                />
                                                <Input
                                                    type="number"
                                                    className="input-styling"
                                                    placeholder="Width"
                                                    value={width1}
                                                    onChange={(e) => setWidth1(e.target.value)}
                                                />
                                                <Input
                                                    className="input-styling"
                                                    placeholder="Height"
                                                    value={height1}
                                                    onChange={(e) => setHeight1(e.target.value)}
                                                />
                                                <Input
                                                    className="input-styling"
                                                    placeholder="Quantity"
                                                    value={qty1}
                                                    onChange={(e) => setQty1(e.target.value)}
                                                />
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <Input
                                                    className="input-styling"
                                                    placeholder="Length"
                                                    value={length2}
                                                    onChange={(e) => setLength2(e.target.value)}
                                                />
                                                <Input
                                                    className="input-styling"
                                                    placeholder="Width"
                                                    value={width2}
                                                    onChange={(e) => setWidth2(e.target.value)}
                                                />
                                                <Input
                                                    className="input-styling"
                                                    placeholder="Height"
                                                    value={height2}
                                                    onChange={(e) => setHeight2(e.target.value)}
                                                />
                                                <Input
                                                    className="input-styling"
                                                    placeholder="Quantity"
                                                    value={qty2}
                                                    onChange={(e) => setQty2(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <h5 className={styleDarkMode + " input-styling"}>Carton Size (0 is not a valid dimension):</h5>
                                            <div style={{ display: "flex" }}>
                                                <Input
                                                    className="input-styling"
                                                    placeholder="Length"
                                                    value={length1}
                                                    onChange={(e) => setLength1(e.target.value)}
                                                />
                                                <Input
                                                    type="number"
                                                    className="input-styling"
                                                    placeholder="Width"
                                                    value={width1}
                                                    onChange={(e) => setWidth1(e.target.value)}
                                                />
                                                <Input
                                                    className="input-styling"
                                                    placeholder="Height"
                                                    value={height1}
                                                    onChange={(e) => setHeight1(e.target.value)}
                                                />
                                                <Input
                                                    className="input-styling"
                                                    placeholder="Quantity"
                                                    value={qty1}
                                                    onChange={(e) => setQty1(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <Button style={{ backgroundColor: "#a12" }} onClick={() => clearInputs()}>
                                    Clear
                                </Button>
                                <Button color="success" disabled={disableSaveBtn()} onClick={() => saveShipmentInformation()}>
                                    Save
                                </Button>
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </div>
            {collectCartonWeight ? (
                <div className="centered-div">
                    <h4>Enter average carton weight:</h4>
                    <Input type="number" placeholder="Average weight (lbs.)" onChange={(e) => setCartonWeight(Number(e.target.value))} />
                    <div className="buttons">
                        <Button color="danger" onClick={() => setCollectCartonWeight(false)}>
                            Cancel
                        </Button>
                        <Button disabled={cartonWeight === 0} color="success" onClick={() => shipmentWeightFromCarton()}>
                            Continue
                        </Button>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export default ShipmentInformationInput;
