import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import Override from "../../../models/Override";
import ShipmentPo from "../../../models/ShipmentPo";
import ValidatedPo from "../../../models/ValidatedPo";
import { IS_LOADING } from "../../constants/loadingStatusConstants/loadingStatus.constants";
import { ERROR_NOTIFICATION } from "../../constants/notificationConstants/notification.constants";
import { ADD_OVERRIDE } from "../../constants/overrideConstants/override.constants";
import {
    ADD_SHIPMENT_PO,
    FETCH_PO_STORE,
    FETCH_SHIPMENT_POS,
    REMOVE_SHIPMENT_PO,
    SET_SHIPMENT_POS,
    VALIDATE_SHIPMENT_PO,
} from "../../constants/poValidationConstants/PoValidation.Constants";
import { shipmentPoSet } from "../../reducers/shipmentPoReducer/ShipmentPo.Reducer";
import { ResponseStatus } from "../_rootSaga/ResponseStatus";

function validateCancelDate(inDate: Date): boolean {
    const cancelDate = new Date(inDate);
    cancelDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return cancelDate.getTime() >= today.getTime();
}

function validateEarlyShipDate(inDate: Date): boolean {
    const shipDate = new Date(inDate);
    shipDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const sevenDaysAgo = new Date();
    const earlyShipGracePeriodDays = 7;
    sevenDaysAgo.setDate(today.getDate() + earlyShipGracePeriodDays);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    return shipDate.getTime() > sevenDaysAgo.getTime();
}

function* fetchShipmentPos(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const shipmentId: string = action.payload;
        const request: string = `${process.env.REACT_APP_API_URL}/shipment/${shipmentId}/ShipmentPo`;
        const response: ResponseStatus<ShipmentPo[]> = yield axios.get(request);
        const shipmentPos: ShipmentPo[] = response.data;
        yield put(shipmentPoSet(shipmentPos));
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: `Error fetching shipment POs. Shipment id: ${action.payload}` });
        yield put({ type: SET_SHIPMENT_POS, payload: [] });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* validateShipmentPo(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    const setPoNumber = action.payload.setPoNumber;
    try {
        const setEarlyShip = action.payload.setEarlyShip;
        const toggleModalVisibility = action.payload.toggleModalVisibility;
        const toggleManualPoModalVisibility = action.payload.toggleManualPoModalVisibility;
        let poStore: number = action.payload.poStoreNum ?? 0;
        let shipmentPo: ShipmentPo = action.payload.newShipmentPo;
        let request: string = `${process.env.REACT_APP_API_URL}/PurchaseOrder/${shipmentPo.po}`;
        const response: ResponseStatus<ValidatedPo> = yield axios.get(request);
        const validatedShipmentPo: ValidatedPo = response.data;
        if (poStore !== 0 && validatedShipmentPo.output[0].poStoreNumber !== poStore) {
            window.alert("That PO is for a different store and cannot be added to this shipment.");
            setPoNumber("");
        } else if (validatedShipmentPo.success === "1") {
            yield put({ type: FETCH_PO_STORE, payload: validatedShipmentPo.output[0].poStoreNumber });
            if (!validateCancelDate(validatedShipmentPo.output[0].dateCancelled)) {
                toggleModalVisibility();
            } else if (validateEarlyShipDate(validatedShipmentPo.output[0].dateShipped)) {
                setEarlyShip(true);
                toggleModalVisibility();
            } else {
                shipmentPo.manualPo = validatedShipmentPo.output[0].manualPo;
                yield put({
                    type: ADD_SHIPMENT_PO,
                    payload: {
                        newShipmentPo: shipmentPo,
                        poStoreNum: validatedShipmentPo.output[0].poStoreNumber,
                        newOverride: new Override(),
                    },
                });
            }
        } else {
            toggleManualPoModalVisibility();
        }
    } catch (error) {
        setPoNumber("");
        yield put({
            type: ERROR_NOTIFICATION,
            payload: `Error validating PO. PO#: ${action.payload.newShipmentPo.po}`,
        });
        yield put({ type: SET_SHIPMENT_POS, payload: [] });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* addShipmentPo(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const shipmentPo: ShipmentPo = action.payload.newShipmentPo;
        const newOverride: Override = action.payload.newOverride ?? null;
        let poStoreNum: number = action.payload.poStoreNum;
        if (shipmentPo.po !== 0) {
            shipmentPo.validated = true;
        }
        const poRequest: string = `${process.env.REACT_APP_API_URL}/shipment/${shipmentPo.shipmentId}/ShipmentPo`;
        const request: ResponseStatus<ShipmentPo> = yield axios.post(poRequest, shipmentPo);
        const newShipmentPo: ShipmentPo = request.data;
        if (newOverride !== null && newOverride.OverrideTypeId !== 0) {
            newOverride.ShipmentPoId = newShipmentPo.id;
            yield put({
                type: ADD_OVERRIDE,
                payload: {
                    newOverride,
                    newShipmentPo,
                },
            });
        }
        yield put({ type: FETCH_SHIPMENT_POS, payload: shipmentPo.shipmentId });
        if (poStoreNum !== 0 && poStoreNum !== undefined) yield put({ type: FETCH_PO_STORE, payload: poStoreNum });
    } catch (error) {
        yield put({
            type: ERROR_NOTIFICATION,
            payload: `Error adding PO to shipment. PO#: ${action.payload.newShipmentPo.po}`,
        });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* removeShipmentPo(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let shipmentId: string = action.payload.shipmentId;
        let shipmentPoId: number = action.payload.shipmentPoId;
        let request: string = `${process.env.REACT_APP_API_URL}/shipment/${shipmentId}/ShipmentPo/${shipmentPoId}`;
        yield axios.delete(request);
        yield put({ type: FETCH_SHIPMENT_POS, payload: shipmentId });
    } catch (error) {
        yield put({
            type: ERROR_NOTIFICATION,
            payload: `Error removing PO from shipment. Shipment id: ${action.payload.shipmentId}.`,
        });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* shipmentPoSaga() {
    yield takeEvery(FETCH_SHIPMENT_POS, fetchShipmentPos);
    yield takeEvery(ADD_SHIPMENT_PO, addShipmentPo);
    yield takeEvery(REMOVE_SHIPMENT_PO, removeShipmentPo);
    yield takeEvery(VALIDATE_SHIPMENT_PO, validateShipmentPo);
}

export default shipmentPoSaga;
